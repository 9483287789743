import { withDependencies } from '@wix/thunderbolt-ioc'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	IPageDidMountHandler,
	IPageDidUnmountHandler,
} from '@wix/thunderbolt-symbols'

const tabClasses = ['focus-ring-active', 'keyboard-tabbing-on']

const accessibilityFactory = (window: BrowserWindow): IPageDidMountHandler & IPageDidUnmountHandler => {
	const addFocusRingAndKeyboardTabbingOnClasses = (event: KeyboardEvent): void => {
		if (event.key === 'Tab') {
			// TODO: Think of a better way to get the site container in page features
			const target: HTMLElement = window!.document.getElementById('SITE_CONTAINER')!
			target.classList.add(...tabClasses)
		}
	}

	const removeKeyboardTabbingOnClass = (): void => {
		const target: HTMLElement = window!.document.getElementById('SITE_CONTAINER')!
		target.classList.remove('keyboard-tabbing-on')
	}
	return {
		pageDidMount() {
			window!.addEventListener('keydown', addFocusRingAndKeyboardTabbingOnClasses)
			window!.addEventListener('click', removeKeyboardTabbingOnClass)
		},
		pageDidUnmount() {
			window!.removeEventListener('keydown', addFocusRingAndKeyboardTabbingOnClasses)
			window!.removeEventListener('click', removeKeyboardTabbingOnClass)
		},
	}
}

export const Accessibility = withDependencies([BrowserWindowSymbol], accessibilityFactory)
