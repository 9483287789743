import { withDependencies } from '@wix/thunderbolt-ioc'
import { BatchingStrategy, IRendererPropsProvider, RendererProps } from '../types'
import { RendererPropsProviderSym, BatchingStrategySymbol } from '../symbols'
import React from 'react'
import ReactDOM from 'react-dom'
import { IRenderer } from '@wix/thunderbolt-symbols'
import { createPromise } from '@wix/thunderbolt-commons'

const { resolver: appDidMountResolver, promise: waitForAppDidMount } = createPromise()

const reactClientRenderer = (
	rendererProps: IRendererPropsProvider,
	batchingStrategy: BatchingStrategy
): IRenderer<RendererProps, Promise<void>> => ({
	getRendererProps: rendererProps.getRendererProps,
	init: async () => {
		await rendererProps.resolveRendererProps()
	},
	render: async () => {
		await window.reactAndReactDOMLoaded
		const App = require('../components/App').default // App creates a React Context on module state, so it has to be evaluated once React is defined.
		const target = document.getElementById('SITE_CONTAINER') as HTMLElement
		ReactDOM.hydrate(
			<React.StrictMode>
				<App
					{...rendererProps.getRendererProps()}
					batchingStrategy={batchingStrategy}
					onDidMount={appDidMountResolver}
				/>
			</React.StrictMode>,
			target
		)
		await waitForAppDidMount
	},
})

export const ReactClientRenderer = withDependencies(
	[RendererPropsProviderSym, BatchingStrategySymbol],
	reactClientRenderer
)

export const appDidMountPromise = waitForAppDidMount
