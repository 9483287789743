import { withDependencies } from '@wix/thunderbolt-ioc'
import {
	DROPDOWN_MENU_COMPONENT_TYPE,
	EXPANDABLE_MENU_COMPONENT_TYPE,
	VERTICAL_MENUS_COMPONENTS_TYPES,
	STYLABLE_HORIZONTAL_MENU_TYPE,
} from './relevantComponentTypes'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import { IComponentPropsExtender } from 'feature-components'
import { ViewerModel, ViewerModelSym } from '@wix/thunderbolt-symbols'

const VerticalMenusFactory = (
	viewerModel: ViewerModel,
	urlHistoryManager: IUrlHistoryManager
): IComponentPropsExtender<{ currentPageHref: string }, unknown> => {
	const fullUrl = urlHistoryManager.getFullUrlWithoutQueryParams()

	return {
		componentTypes: [...VERTICAL_MENUS_COMPONENTS_TYPES, STYLABLE_HORIZONTAL_MENU_TYPE],
		getExtendedProps: () => ({
			currentPageHref: fullUrl,
		}),
	}
}

export const VerticalMenus = withDependencies([ViewerModelSym, UrlHistoryManagerSymbol], VerticalMenusFactory)

const ExpandableAndDropdownMenusFactory = (
	viewerModel: ViewerModel,
	urlHistoryManager: IUrlHistoryManager
): IComponentPropsExtender<{ currentPrimaryPageHref: string }, unknown> => {
	const fullUrl = urlHistoryManager.getFullUrlWithoutQueryParams()

	return {
		componentTypes: [EXPANDABLE_MENU_COMPONENT_TYPE, DROPDOWN_MENU_COMPONENT_TYPE],
		getExtendedProps: () => ({
			currentPrimaryPageHref: fullUrl,
		}),
	}
}

export const ExpandableAndDropdownMenus = withDependencies(
	[ViewerModelSym, UrlHistoryManagerSymbol],
	ExpandableAndDropdownMenusFactory
)
