import { withDependencies } from '@wix/thunderbolt-ioc'
import { IStructureStore, Structure } from '@wix/thunderbolt-symbols'
import ReactDOM from 'react-dom'
import { BatchingStrategy } from '../types'

const createBatchingStrategy = (batchingFunction: (update: () => void) => void): BatchingStrategy => {
	let promise: Promise<void> | null = null
	const batchingStrategy: BatchingStrategy = {
		batch: (fn) => {
			if (!promise) {
				batchingFunction(fn)
			} else {
				promise.then(() => {
					batchingFunction(fn)
					promise = null
				})
			}
		},
		batchAsync: (waitBeforeBatch) => {
			promise = waitBeforeBatch
		},
	}
	return batchingStrategy
}

export const ClientBatchingStrategy = withDependencies<BatchingStrategy>(
	[Structure],
	(structureStore: IStructureStore) => {
		let fns = [] as Array<() => void>
		let finishRenderFirstPage = false
		return createBatchingStrategy((fn) => {
			const isDuringNavigation = structureStore.get('SITE_PAGES')?.components
				? structureStore.get('SITE_PAGES').components.length === 0
				: true
			finishRenderFirstPage = finishRenderFirstPage || !isDuringNavigation
			if (isDuringNavigation && finishRenderFirstPage) {
				fns.push(fn)
				return
			}
			if (fns.length) {
				const localFns = [...fns, fn]
				fns = []
				ReactDOM.unstable_batchedUpdates(() => {
					localFns.forEach((deferredFunc) => deferredFunc())
				})
			} else {
				ReactDOM.unstable_batchedUpdates(fn)
			}
		})
	}
)

export const ServerBatchingStrategy = withDependencies<BatchingStrategy>([], () =>
	createBatchingStrategy((fn) => {
		fn()
	})
)
